// extracted by mini-css-extract-plugin
export var paymentPolicy = "PaymentPolicy-module--paymentPolicy--1zel0";
export var content = "PaymentPolicy-module--content--3ceQO";
export var container = "PaymentPolicy-module--container--gBWWp";
export var title = "PaymentPolicy-module--title--2YB4N";
export var subtitle = "PaymentPolicy-module--subtitle--1iJjl";
export var cards = "PaymentPolicy-module--cards--FaHNZ";
export var cardImagesContainer = "PaymentPolicy-module--cardImagesContainer--CiHzU";
export var cardImage = "PaymentPolicy-module--cardImage--Sfk6k";
export var description = "PaymentPolicy-module--description--3UMC_";
export var buttonToTop = "PaymentPolicy-module--buttonToTop--2FN9k";
export var buttonToTopHidden = "PaymentPolicy-module--buttonToTopHidden--2mwZ1";
export var footerContainer = "PaymentPolicy-module--footerContainer--a6erW";